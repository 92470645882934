<template>
<div>
  <v-card color="grey lighten-5" rounded="0" flat min-height="160">
    <v-card-title class="text-h4 justify-left titulo-card pl-7">Students Statistics</v-card-title>
    <v-row>
      <v-col class="col-4">
        <v-row>
          <v-col cols="5" class="my-lg-auto text-center">
            <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Classroom</p>
          </v-col>
          <v-col cols="7">
            <v-select v-model="classroom" outlined :items="classrooms" item-text="name" menu-props="auto" label="Select Classroom" hide-details return-object single-line @change="SelectClassroom"></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-6">
        <v-row>
          <v-slide-x-transition hide-on-leave>
            <v-col v-show="toogleSeacrh">
              <v-row>
                <v-col cols="5 my-lg-auto text-center">
                  <p class="text-h6 mb-3"><v-icon>mdi-account-supervisor-circle</v-icon>Select Student</p>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-autocomplete v-model="studentSelected" outlined item-text="name" :items="studentsData" return-object label="Students"></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-btn small color="rgba(17,97,94,1)" dark @click="SearchStudent" height="50"><v-icon>mdi-chart-bar</v-icon>Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave>
            <v-col v-show="!toogleSeacrh">
              <v-row>
                <v-col cols="5 my-lg-auto text-center">
                  <p class="text-h6 mb-1"><v-icon>mdi-account-group</v-icon>Select groups</p>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="group" outlined :items="groups" item-text="name" menu-props="auto" label="Select Group" hide-details return-object single-line :disabled="groups.length==0"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn small color="rgba(17,97,94,1)" dark @click="SearchGroup" height="50"><v-icon>mdi-chart-bar</v-icon>Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-slide-x-transition>  
        </v-row>
      </v-col>

      <!-- <v-col class=" text-center" v-if="haveGroups">
        <v-card @click="toogleSeacrh = !toogleSeacrh" color="teal accent-2" class="mx-5" min-height="55">
          <v-icon>mdi-cached</v-icon>
          <p v-if="toogleSeacrh">Groups</p>
          <p v-else>Students</p>
        </v-card>
      </v-col> -->
    </v-row>
  </v-card>
<div id="fondototal" v-bind:style="{ minHeight: minHeight + 'px' }">
  <v-container>
    <!-- <v-row dense>
        <v-col cols="3">
          <v-card elevation="2" class="mb-3 px-3 pt-5 pb-6">
            <h4>Classroom</h4>
            <v-select v-model="classroom" :items="classrooms" item-text="name" menu-props="auto" label="Select Classroom" hide-details prepend-icon="mdi-account-supervisor-circle" return-object single-line @change="SelectClassroom"></v-select>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card elevation="2" class="mb-3 pa-3">
            <v-row>
              <div class="col-md-3">
                <h4>Generate reports by groups</h4>
                <v-select v-model="group" :items="groups" item-text="name" menu-props="auto" label="Select Group" hide-details prepend-icon="mdi-account-group" return-object single-line :disabled="groups.length==0"></v-select>
              </div>
              <div class="col-md-2">
                
              </div>
              <div class="col-md-5">
                <h4>Generate reports by students</h4>
                <v-autocomplete v-model="studentSelected" item-text="name" :items="studentsData" return-object label="Students"></v-autocomplete>
              </div>
              <div class="col-md-2">
                <v-btn small color="primary" dark @click="SearchStudent" height="50" class="mt-3">Search</v-btn>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
    <v-toolbar v-if="showChart" color="cyan lighten-5"  class="ma-2">
      <v-toolbar-title>
        <p class="ma-4 text-h5 text--primary">Class: {{ classroom.name }} </p>
      </v-toolbar-title>
      <v-spacer></v-spacer>
        <p class="ma-4 text-h5 text--primary"><b>{{ textTitle}}</b> </p>
    </v-toolbar>
    <v-card elevation="3" class="mb-4" v-if="showChart">
   
      <v-card-title>
        Mission 1 : Los Sonidos
      </v-card-title>
      <div v-if="Mission1Clean">
        <p class="text-center text-h6" v-if="InsufficientDataMission1">Insufficient Data</p>
        <p class="text-center text-h6" v-else>Need to play to show report</p>
        
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertos" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Letters to practice
          </div>
          <bar :chart-data="barChartLetters" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTest" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTime }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 2 : Las Sílabas
      </v-card-title>
      <div v-if="Mission2Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertosM2" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Syllables to practice
          </div>
          <bar :chart-data="barChartLettersM2" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM2" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM2 }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 3 : Palabras de 2 sílabas
      </v-card-title>
      <div v-if="Mission3Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertosM3" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Words to practice by letter
          </div>
          <bar :chart-data="barChartLettersM3" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM3" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM3 }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 4 : Extensiones, sílabas inversas, sílabas con inicio
      </v-card-title>
      <div v-if="Mission4Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertosM4" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Extensiones
          </div>
          <bar :chart-data="barChartLettersM4Extensiones" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Sílabas Inversas
          </div>
          <bar :chart-data="barChartLettersM4Inversas" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Sílabas con Inicio
          </div>
          <bar :chart-data="barChartLettersM4Inicio" :options="barChartOptions" :height="250"/>
        </div>
        <div class="col-md-3 col-sm-1"></div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM4" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM4 }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 5 : Tres Sílabas
      </v-card-title>
      <div v-if="Mission5Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-4">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertosM5" :options="CharAciertosOptions" :height="200"/>
        </div>
        <!-- <div class="col-md-3">
          <div class="subtitle-statistics">
            Words to practice by letter
          </div>
          <bar :chart-data="barChartLettersM3" :options="barChartOptions" :height="250"/>
        </div> -->
        <div class="col-md-4">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM5" :options="CharAciertosOptions" :height="200"/>
        </div>
        <div class="col-md-4">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM5 }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 6: Sílabas trabadas y especiales
      </v-card-title>
      <div v-if="Mission6Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate
          </div>
          <pie :chart-data="CharAciertosM6" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-2">
          <div class="subtitle-statistics">
            Sílabas Trabadas
          </div>
          <bar :chart-data="barChartLettersM6Trabadas" :options="barChartOptions" :height="350"/>
        </div>
        <div class="col-md-2">
          <div class="subtitle-statistics">
            Sílabas Especiales
          </div>
          <bar :chart-data="barChartLettersM6Especiales" :options="barChartOptions" :height="350"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM6" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-2">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM6 }}
          </div>
        </div>
      </v-row>
    </v-card>
    <v-card elevation="3" class="mb-4" v-if="showChart">
      <v-card-title>
        Mission 7: Lectura de Oraciones
      </v-card-title>
      <div v-if="Mission7Clean">
        <p class="text-center text-h6">Need to play to show report</p>
      </div>
      <v-row v-else>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate: Sustantivo
          </div>
          <pie :chart-data="CharAciertosM7Sustantivo" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate: Verbo
          </div>
          <pie :chart-data="CharAciertosM7Verbo" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate: Adjetivo
          </div>
          <pie :chart-data="CharAciertosM7Adjetivo" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Success rate: Oracion
          </div>
          <pie :chart-data="CharAciertosM7Oracion" :options="CharAciertosOptions" :height="250"/>
        </div>
        
        
        <div class="col-md-3 col-sm-1"></div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Final evaluation
          </div>
          <pie :chart-data="CharFinalTestM7" :options="CharAciertosOptions" :height="250"/>
        </div>
        <div class="col-md-3">
          <div class="subtitle-statistics">
            Use time
          </div>
          <div class="time-use">
            {{ UseTimeM7 }}
          </div>
        </div>
        
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="2000">
      Select Classroom please
    </v-snackbar>
  </v-container>
</div>
</div>
</template>
<script>
import { db } from '../../../plugins/firebase'
import Pie from '../../../components/PieChart'
import Bar from '../../../components/BarChart'
export default {
  components: {
    Pie, Bar
  },
  data () {
    return {
      toogleSeacrh: true,
      classroom: '',
      group: '',
      user: {},
      classrooms: [],
      groups: [],
      studentsData: [],
      studentSelected: '',
      barChartData: {},
      barChartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },
      pieChartData: {},
      pieChartOptions: {
        responsive: true,
      },
      CharAciertos: {},
      CharAciertosOptions: {
        responsive: true,
      },
      barChartLetters:{},
      CharFinalTest: {},
      CharAciertosM2: {},
      barChartLettersM2: {},
      CharFinalTestM2: {},
      UseTimeM2: '',
      CharAciertosM3: {},
      barChartLettersM3: {},
      CharFinalTestM3: {},
      UseTimeM3: '',
      CharAciertosM4: {},
      barChartLettersM4Extensiones: {},
      barChartLettersM4Inversas: {},
      barChartLettersM4Inicio: {},
      CharFinalTestM4: {},
      UseTimeM4: '',
      CharAciertosM5: {},
      barChartLettersM5: {},
      CharFinalTestM5: {},
      UseTimeM5: '',
      CharAciertosM6: {},
      barChartLettersM6Trabadas: {},
      barChartLettersM6Especiales: {},
      CharFinalTestM6: {},
      UseTimeM6: '',
      UseTime: '',
      CharAciertosM7Adjetivo: {},
      CharAciertosM7Oracion: {},
      CharAciertosM7Sustantivo:{},
      CharAciertosM7Verbo: {},
      CharFinalTestM7:{},
      UseTimeM7: '',
      snackbar: false,
      showChart: false,
      textTitle: '',
      minHeight : 500,
      Mission1Clean: false,
      Mission2Clean: false,
      Mission3Clean: false,
      Mission4Clean: false,
      Mission5Clean: false,
      Mission6Clean: false,
      Mission7Clean: false,
      haveGroups: false,
      InsufficientDataMission1: false
    }
  },
  created () {
    this.GetDataTeacher();
    let myHeigth=innerHeight;
    this.minHeight = myHeigth-50;
  },
  methods: {
    GetDataTeacher () {
      let uidLogin = this.$store.state.user.uid;
      console.log(uidLogin);
      db.collection('SchoolTeacher').doc(uidLogin).get().then((doc) => {
        console.log(doc.data())
        this.user = doc.data();
        let ListClassrooms = doc.data().Classrooms;
        let newClassrooms = [];
        if (Object.keys(ListClassrooms).length !== 0) {
          let ids = Object.keys(ListClassrooms);
          ids.forEach(element => {
            newClassrooms.push({ id: element, name: ListClassrooms[element].Name, numberStudents: ListClassrooms[element].NumberStudents, numberGroup: ListClassrooms[element].NumberGroup })
          });
        }
        this.classrooms = newClassrooms;
      })
    },
    SelectClassroom () {
      // console.log(this.classroom)
      let uidLogin = this.$store.state.user.uid;
      if (this.classroom.numberGroup!=0) {
        this.haveGroups = true;
      }else{
        this.haveGroups = false;
        this.toogleSeacrh = true;
      }
      db.collection('SchoolTeacher').doc(uidLogin).collection('Classrooms').doc(this.classroom.id).get().then((doc) => {
        let preGroups = doc.data().Groups;
        let newGroup = [{ id:-1, name: 'All Groups'}];
        if (Object.keys(preGroups).length !== 0) {
          let ids = Object.keys(preGroups);
          ids.forEach(element => {
            newGroup.push({ id: element, name: preGroups[element].Name })
          });
        }
        this.groups = newGroup;
        let mainGroup = doc.data().MainGroup;
        let idStudents = Object.keys(mainGroup);
        let allStudents = [];
        idStudents.forEach(element => {
          allStudents.push({ id: element, name: mainGroup[element].Name + ' ' + mainGroup[element].LastName, email: mainGroup[element].Email, group: mainGroup[element].Group })
        });
        allStudents.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.studentsData = allStudents;
        // let ids = Object.keys(mainGroup);
        // this.studentsData = [];
  
        // console.log(TotalSuccess)
      })
    },
    SearchGroup () {
      if (this.classroom == '') {
        this.snackbar = true;
        return;
      }
      this.showChart = true;
      this.textTitle = this.group.name; 
      // console.log(this.group)
      this.studentSelected = '';
      let idGroup = this.group.id
      let GroupPaint = [];
      if (idGroup == -1){
        GroupPaint = this.studentsData;
      }else{
        this.studentsData.forEach(element => {
          if (element.group == idGroup) {
            GroupPaint.push(element)
          }
        });
      }
      let TotalSuccess = 0;
      let TimeUse = 0;
      let letersForPractice  = [];
      let FinalTest = 0
      // mision 2
      let TotalSuccessM2 = 0;
      let TimeUseM2 = 0;
      let letersForPracticeM2  = [];
      let FinalTestM2 = 0
      // mision 3
      let TotalSuccessM3 = 0;
      let TimeUseM3 = 0;
      let letersForPracticeM3  = [];
      let FinalTestM3 = 0
      // mision 4
      let TotalSuccessM4 = 0;
      let TimeUseM4 = 0;
      let letersForPracticeM4  = [];
      let FinalTestM4 = 0
      //mision 5
      let TotalSuccessM5 = 0;
      let TimeUseM5 = 0;
      let letersForPracticeM5  = [];
      let FinalTestM5 = 0;

      let usersWithoutPlayM1 = 0;
      let usersWithoutPlayM2 = 0;
      let usersWithoutPlayM3 = 0;
      let usersWithoutPlayM4 = 0;
      let usersWithoutPlayM5 = 0;
      let bar = new Promise((resolve, reject) => {
        GroupPaint.forEach((element, index, array) => {
          db.collection('Students').doc(element.id).get().then((res) => {
            //console.log(res.data())
            let DataStudent = res.data();
            if (Object.prototype.hasOwnProperty.call(DataStudent, 'Alumno')) {
              TotalSuccess += parseFloat(res.data().Alumno.AprendizajeM1v2.PorcentajeAciertoT)
              FinalTest += parseFloat(res.data().Alumno.AprendizajeM1v2.PruebaFinal)
              TimeUse += parseFloat(res.data().Alumno.AprendizajeM1v2.TiempoTotalUso)
              letersForPractice.push(this.SearchLettersDeficient(res.data().Alumno.AprendizajeM1v2.UnidadesAAprendizaje));
              //mision 2
              if (res.data().Alumno.AprendizajeM2v2 == null) {
                usersWithoutPlayM2 ++;
              }else{
              TotalSuccessM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.PorcentajeAciertoT);
              FinalTestM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.PruebaFinal);
              TimeUseM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.TiempoTotalUso)
              console.log(res.data().Alumno.AprendizajeM2v2.UnidadesAAprendizaje)
              letersForPracticeM2 =this.SearchLettersDeficient(res.data().Alumno.AprendizajeM2v2.UnidadesAAprendizaje);
              }
              //mision 3
              if (res.data().Alumno.AprendizajeM3v2 == null) {
                usersWithoutPlayM3 ++;
              }else{
              TotalSuccessM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.PorcentajeAciertoT);
              FinalTestM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.PruebaFinal);
              TimeUseM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.TiempoTotalUso);
              letersForPracticeM3 = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM3v2.UnidadesAAprendizaje);
              }
              //mision 4
              console.log(res.data().Alumno.AprendizajeM4v2)
              if (res.data().Alumno.AprendizajeM4v2 == null) {
                usersWithoutPlayM4 ++;
              }else{
              TotalSuccessM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.PorcentajeAciertoT);
              FinalTestM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.PruebaFinal);
              TimeUseM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.TiempoTotalUso);
              //letersForPracticeM4 = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM4v2.UnidadesAAprendizaje);
              }
              //mision 5
              if (res.data().Alumno.AprendizajeM5v2 == null) {
                usersWithoutPlayM5 ++;
              }else{
              TotalSuccessM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.PorcentajeAciertoT);
              FinalTestM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.PruebaFinal);
              TimeUseM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.TiempoTotalUso);
              }
            }else{
              //console.log('data')
              usersWithoutPlayM1 ++;
              usersWithoutPlayM2 ++;
              usersWithoutPlayM3 ++;
              usersWithoutPlayM4 ++;
              usersWithoutPlayM5 ++;
            }
            if (index === array.length - 1) resolve();
          })
        })
      });
      bar.then(() => {
        if (usersWithoutPlayM1 == GroupPaint.length) {
          this.Mission1Clean = true;
        }
        else{
          this.Mission1Clean = false;
        }
        if (usersWithoutPlayM2 == GroupPaint.length) {
          this.Mission2Clean = true;
        }
        else{
          this.Mission2Clean = false;
        }
        if (usersWithoutPlayM3 == GroupPaint.length) {
          this.Mission3Clean = true;
        }else{
          this.Mission3Clean = false;
        }
        if (usersWithoutPlayM4 == GroupPaint.length) {
          this.Mission4Clean = true;
        }else{
          this.Mission4Clean = false;
        }
        if (usersWithoutPlayM5 == GroupPaint.length) {
          this.Mission5Clean = true;
        }else{
          this.Mission5Clean = false;
        }

        // console.log(letersForPracticeM3);
        letersForPractice = this.SelectErrorArray(letersForPractice);
        // letersForPracticeM2 = this.SelectErrorArray(letersForPracticeM2);
        // letersForPracticeM3 = this.SelectErrorArray(letersForPracticeM3);
        this.PaintCharts(TotalSuccess, letersForPractice, FinalTest, TimeUse, GroupPaint.length, TotalSuccessM2, FinalTestM2, TimeUseM2, letersForPracticeM2, TotalSuccessM3, FinalTestM3, TimeUseM3, letersForPracticeM3, TotalSuccessM4, FinalTestM4, TimeUseM4, letersForPracticeM4);
      });
    },
    PaintCharts (TotalSuccess, letersForPractice, FinalTest, TimeUse, lengthStudents, TotalSuccessM2, FinalTestM2, TimeUseM2, letersForPracticeM2, TotalSuccessM3, FinalTestM3, TimeUseM3, letersForPracticeM3, TotalSuccessM4, FinalTestM4, TimeUseM4, letersForPracticeM4Extensiones, letersForPracticeM4Inversas, letersForPracticeM4Inicio, TotalSuccessM5, FinalTestM5, TimeUseM5, letersForPracticeM5, TotalSuccessM6, FinalTestM6, TimeUseM6, letersForPracticeM6Trabadas, letersForPracticeM6Especiales, TotalSuccessM7Adjetivo, TotalSuccessM7Oracion, TotalSuccessM7Sustantivo, TotalSuccessM7Verbo, FinalTestM7, TimeUseM7) {
        // primer grafico
        TotalSuccess = (TotalSuccess / lengthStudents).toFixed(2);
        TotalSuccess = (TotalSuccess * 100)
        this.CharAciertos = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccess, (100 - TotalSuccess)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        
        let LabelLetter = [];
        let DataSuccessM1 =[];
        let DataErrorM1 = [];
        letersForPractice.forEach(element => {
          LabelLetter.push(element.id);
          DataSuccessM1.push(element.PorcentajeAcierto);
          DataErrorM1.push((100-element.PorcentajeAcierto).toFixed(2));
        });
        this.barChartLetters = {
          labels: LabelLetter,
          datasets: [
            {
              label: 'Success',
              //data: [ letersForPractice[0].PorcentajeAcierto, letersForPractice[1].PorcentajeAcierto, letersForPractice[2].PorcentajeAcierto, letersForPractice[3].PorcentajeAcierto, letersForPractice[4].PorcentajeAcierto],
              data: DataSuccessM1,
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              //data: [(100-letersForPractice[0].PorcentajeAcierto).toFixed(2), (100-letersForPractice[1].PorcentajeAcierto).toFixed(2), (100-letersForPractice[2].PorcentajeAcierto).toFixed(2), (100-letersForPractice[3].PorcentajeAcierto).toFixed(2), (100-letersForPractice[4].PorcentajeAcierto).toFixed(2)],
              data: DataErrorM1,
              backgroundColor: '#ffa15d'
            },
          ]
        }
        // tercer grafico
        console.log(FinalTest)
        if (FinalTest>0) {
          FinalTest = Math.round( FinalTest / lengthStudents );
          //FinalTest = (FinalTest * 100)
          this.CharFinalTest = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTest, (100 - FinalTest)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        } else {
          this.CharFinalTest = { 
            labels: [],
            datasets: []
          };
        }
        
        // cuarto grafico
        let Time = Math.round( TimeUse );
        this.UseTime = this.secondsToString(Time)
      if (this.Mission2Clean == false) {
        // M2
        TotalSuccessM2 = (TotalSuccessM2 / lengthStudents).toFixed(2);
        TotalSuccessM2 = (TotalSuccessM2 * 100)
        this.CharAciertosM2 = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM2, (100 - TotalSuccessM2)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        let LabelLetterM2 = [];
        let DataSuccessM2 = [];
        let DataErrorM2 = [];
        letersForPracticeM2.forEach(element => {
          LabelLetterM2.push(element.id);
          DataSuccessM2.push(element.PorcentajeAcierto);
          DataErrorM2.push(100-element.PorcentajeAcierto);
        });
        this.barChartLettersM2 = {
          labels: LabelLetterM2,
          datasets: [
            {
              label: 'Success',
              //data: [letersForPracticeM2[0].PorcentajeAcierto, letersForPracticeM2[1].PorcentajeAcierto, letersForPracticeM2[2].PorcentajeAcierto, letersForPracticeM2[3].PorcentajeAcierto, letersForPracticeM2[4].PorcentajeAcierto],
              data: DataSuccessM2,
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              //data: [(100-letersForPracticeM2[0].PorcentajeAcierto), (100-letersForPracticeM2[1].PorcentajeAcierto), (100-letersForPracticeM2[2].PorcentajeAcierto), (100-letersForPracticeM2[3].PorcentajeAcierto), (100-letersForPracticeM2[4].PorcentajeAcierto)],
              data: DataErrorM2,
              backgroundColor: '#ffa15d'
            },
          ]
        }
        // tercer grafico
        console.log(this.CharFinalTestM2);
        if (FinalTestM2>0) {
          FinalTestM2 = (FinalTestM2 / lengthStudents).toFixed(2);
          //FinalTestM2 = (FinalTestM2 * 100)
          this.CharFinalTestM2 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM2, (100 - FinalTestM2)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        } else {
          this.CharFinalTestM2 = { 
            labels: [],
            datasets: []
          };
        }
        // cuarto grafico
        let TimeM2 = Math.round( TimeUseM2 );
        this.UseTimeM2 = this.secondsToString(TimeM2)
      }
      if (this.Mission3Clean == false) {
        // M3
        TotalSuccessM3 = (TotalSuccessM3 / lengthStudents).toFixed(2);
        TotalSuccessM3 = (TotalSuccessM3 * 100)
        this.CharAciertosM3 = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM3, (100 - TotalSuccessM3)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        let LabelLetterM3 = [];
        let DataSuccessM3 = [];
        let DataErrorM3 = [];
        // console.log(letersForPracticeM3)
        letersForPracticeM3.forEach(element => {
          LabelLetterM3.push(element.id);
          DataSuccessM3.push(element.PorcentajeAcierto);
          DataErrorM3.push(100-element.PorcentajeAcierto);
        });
        this.barChartLettersM3 = {
          labels: LabelLetterM3,
          datasets: [
            {
              label: 'Success',
              //data: [letersForPracticeM3[0].PorcentajeAcierto, letersForPracticeM3[1].PorcentajeAcierto, letersForPracticeM3[2].PorcentajeAcierto, letersForPracticeM3[3].PorcentajeAcierto, letersForPracticeM3[4].PorcentajeAcierto],
              data: DataSuccessM3,
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              //data: [(100-letersForPracticeM3[0].PorcentajeAcierto), (100-letersForPracticeM3[1].PorcentajeAcierto), (100-letersForPracticeM3[2].PorcentajeAcierto), (100-letersForPracticeM3[3].PorcentajeAcierto), (100-letersForPracticeM3[4].PorcentajeAcierto)],
              data: DataErrorM3,
              backgroundColor: '#ffa15d'
            },
          ]
        }
        // tercer grafico
        if (FinalTestM3>0) {
          FinalTestM3 = (FinalTestM3 / lengthStudents).toFixed(2);
          //FinalTestM3 = (FinalTestM3 * 100)
          this.CharFinalTestM3 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM3, (100 - FinalTestM3)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        }else {
          this.CharFinalTestM3 = { 
            labels: [],
            datasets: []
          };
        }
        // cuarto grafico
        let TimeM3 = Math.round( TimeUseM3 );
        this.UseTimeM3 = this.secondsToString(TimeM3)
      }
      if (this.Mission4Clean == false) {
        // M4
        TotalSuccessM4 = (TotalSuccessM4 / lengthStudents).toFixed(2);
        TotalSuccessM4 = (TotalSuccessM4 * 100)
        this.CharAciertosM4 = {
          labels: ['success %', 'error %'],
          datasets: [{
            label: ['Visits'],
            data: [TotalSuccessM4, (100 - TotalSuccessM4)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        let LabelLetterM4Extensiones = [];
        LabelLetterM4Extensiones.push(letersForPracticeM4Extensiones[0].id)
        LabelLetterM4Extensiones.push(letersForPracticeM4Extensiones[1].id)
        LabelLetterM4Extensiones.push(letersForPracticeM4Extensiones[2].id)
        this.barChartLettersM4Extensiones = {
          labels: LabelLetterM4Extensiones,
          datasets: [
            {
              label: 'Success',
              data: [letersForPracticeM4Extensiones[0].PorcentajeAcierto, letersForPracticeM4Extensiones[1].PorcentajeAcierto, letersForPracticeM4Extensiones[2].PorcentajeAcierto],
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              data: [(100-letersForPracticeM4Extensiones[0].PorcentajeAcierto), (100-letersForPracticeM4Extensiones[1].PorcentajeAcierto), (100-letersForPracticeM4Extensiones[2].PorcentajeAcierto)],
              backgroundColor: '#ffa15d'
            },
          ]
        }
        let LabelLetterM4Inversas = [];
        LabelLetterM4Inversas.push(letersForPracticeM4Inversas[0].id)
        LabelLetterM4Inversas.push(letersForPracticeM4Inversas[1].id)
        LabelLetterM4Inversas.push(letersForPracticeM4Inversas[2].id)
        this.barChartLettersM4Inversas = {
          labels: LabelLetterM4Inversas,
          datasets: [
            {
              label: 'Success',
              data: [letersForPracticeM4Inversas[0].PorcentajeAcierto, letersForPracticeM4Inversas[1].PorcentajeAcierto, letersForPracticeM4Inversas[2].PorcentajeAcierto],
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              data: [(100-letersForPracticeM4Inversas[0].PorcentajeAcierto), (100-letersForPracticeM4Inversas[1].PorcentajeAcierto), (100-letersForPracticeM4Inversas[2].PorcentajeAcierto)],
              backgroundColor: '#ffa15d'
            },
          ]
        }
        let LabelLetterM4Inicio = [];
        LabelLetterM4Inicio.push(letersForPracticeM4Inicio[0].id)
        LabelLetterM4Inicio.push(letersForPracticeM4Inicio[1].id)
        LabelLetterM4Inicio.push(letersForPracticeM4Inicio[2].id)
        this.barChartLettersM4Inicio = {
          labels: LabelLetterM4Inicio,
          datasets: [
            {
              label: 'Success',
              data: [letersForPracticeM4Inicio[0].PorcentajeAcierto, letersForPracticeM4Inicio[1].PorcentajeAcierto, letersForPracticeM4Inicio[2].PorcentajeAcierto],
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              data: [(100-letersForPracticeM4Inicio[0].PorcentajeAcierto), (100-letersForPracticeM4Inicio[1].PorcentajeAcierto), (100-letersForPracticeM4Inicio[2].PorcentajeAcierto)],
              backgroundColor: '#ffa15d'
            },
          ]
        }
        // tercer grafico
        if (FinalTestM4>0) {
          FinalTestM4 = (FinalTestM4 / lengthStudents).toFixed(2);
          //FinalTestM3 = (FinalTestM3 * 100)
          this.CharFinalTestM4 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM4, (100 - FinalTestM4)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        }else {
          this.CharFinalTestM4 = { 
            labels: [],
            datasets: []
          };
        }
        console.log(TimeUseM4);
        // cuarto grafico
        let TimeM4 = Math.round( TimeUseM4 );
        this.UseTimeM4 = this.secondsToString(TimeM4)
      }
      if (this.Mission5Clean == false) {
        // M5
        TotalSuccessM5 = (TotalSuccessM5 / lengthStudents).toFixed(2);
        TotalSuccessM5 = (TotalSuccessM5 * 100)
        this.CharAciertosM5 = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM5, (100 - TotalSuccessM5)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        let LabelLetterM5 = [];
        // letersForPracticeM4.forEach(element => {
        //   LabelLetterM4.push(element.id);
        // });
        // this.barChartLettersM4 = {
        //   labels: LabelLetterM4,
        //   datasets: [
        //     {
        //       label: 'Success',
        //       data: [letersForPracticeM4[0].PorcentajeAcierto, letersForPracticeM4[1].PorcentajeAcierto, letersForPracticeM4[2].PorcentajeAcierto, letersForPracticeM4[3].PorcentajeAcierto, letersForPracticeM4[4].PorcentajeAcierto],
        //       backgroundColor: '#008f88'
        //     },
        //     {
        //       label: 'Errors',
        //       data: [(100-letersForPracticeM4[0].PorcentajeAcierto), (100-letersForPracticeM4[1].PorcentajeAcierto), (100-letersForPracticeM4[2].PorcentajeAcierto), (100-letersForPracticeM4[3].PorcentajeAcierto), (100-letersForPracticeM4[4].PorcentajeAcierto)],
        //       backgroundColor: '#ffa15d'
        //     },
        //   ]
        // }
        // tercer grafico
        if (FinalTestM5>0) {
          FinalTestM5 = (FinalTestM5 / lengthStudents).toFixed(2);
          //FinalTestM3 = (FinalTestM3 * 100)
          this.CharFinalTestM5 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM5, (100 - FinalTestM5)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        }else {
          this.CharFinalTestM5 = { 
            labels: [],
            datasets: []
          };
        }
        // cuarto grafico
        let TimeM5 = Math.round( TimeUseM5 );
        this.UseTimeM5 = this.secondsToString(TimeM5)
      }
      if (this.Mission6Clean == false) {
        // M6
        TotalSuccessM6 = (TotalSuccessM6 / lengthStudents).toFixed(2);
        TotalSuccessM6 = (TotalSuccessM6 * 100)
        this.CharAciertosM6 = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM6, (100 - TotalSuccessM6)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // segundo grafico
        let LabelLetterM6Trabadas = [];
        letersForPracticeM6Trabadas.length = 3;
        let dataSuccess = []
        let dataError = []
        letersForPracticeM6Trabadas.forEach(element => {
          LabelLetterM6Trabadas.push(element.id);
          dataSuccess.push(element.PorcentajeAcierto);
          dataError.push(100-element.PorcentajeAcierto)
        });
        // LabelLetterM6Trabadas.push(letersForPracticeM6Trabadas[0].id)
        // LabelLetterM6Trabadas.push(letersForPracticeM6Trabadas[1].id)
        // LabelLetterM6Trabadas.push(letersForPracticeM6Trabadas[2].id)
        this.barChartLettersM6Trabadas = {
          labels: LabelLetterM6Trabadas,
          datasets: [
            {
              label: 'Success',
              //data: [letersForPracticeM6Trabadas[0].PorcentajeAcierto, letersForPracticeM6Trabadas[1].PorcentajeAcierto, letersForPracticeM6Trabadas[2].PorcentajeAcierto],
              data: dataSuccess,
              backgroundColor: '#008f88'
            },
            {
              label: 'Errors',
              //data: [(100-letersForPracticeM6Trabadas[0].PorcentajeAcierto), (100-letersForPracticeM6Trabadas[1].PorcentajeAcierto), (100-letersForPracticeM6Trabadas[2].PorcentajeAcierto)],
              data: dataError,
              backgroundColor: '#ffa15d'
            },
          ]
        }
        console.log(letersForPracticeM6Especiales)
        let LabelLetterM6Especiales = [];
        let dataSuccessEspeciales = [];
        let dataErrorEspeciales =[];
        letersForPracticeM6Especiales.length = 2;

        letersForPracticeM6Especiales.forEach(element => {
          console.log(element);
          LabelLetterM6Especiales.push(element.id);
          dataSuccessEspeciales.push(element.PorcentajeAcierto);
          dataErrorEspeciales.push(100-element.PorcentajeAcierto)

        });
        // LabelLetterM6Especiales.push(letersForPracticeM6Especiales[0].id)
        // LabelLetterM6Especiales.push(letersForPracticeM6Especiales[1].id)

          this.barChartLettersM6Especiales = {
            labels: LabelLetterM6Especiales,
            datasets: [
              {
                label: 'Success',
                //data: [letersForPracticeM6Especiales[0].PorcentajeAcierto, letersForPracticeM6Especiales[1].PorcentajeAcierto],
                data: dataSuccessEspeciales,
                backgroundColor: '#008f88'
              },
              {
                label: 'Errors',
                //data: [(100-letersForPracticeM6Especiales[0].PorcentajeAcierto), (100-letersForPracticeM6Especiales[1].PorcentajeAcierto)],
                data: dataErrorEspeciales,
                backgroundColor: '#ffa15d'
              },
            ]
          }

        // tercer grafico
        if (FinalTestM6>0) {
          FinalTestM6 = (FinalTestM6 / lengthStudents).toFixed(2);
          //FinalTestM3 = (FinalTestM3 * 100)
          this.CharFinalTestM6 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM6, (100 - FinalTestM6)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        }else {
          this.CharFinalTestM6 = { 
            labels: [],
            datasets: []
          };
        }
        // cuarto grafico
        let TimeM6 = Math.round( TimeUseM6 );
        this.UseTimeM6 = this.secondsToString(TimeM6)
      }
      if (this.Mission7Clean == false) { // mision 7
        TotalSuccessM7Adjetivo = (TotalSuccessM7Adjetivo / lengthStudents).toFixed(2);
        TotalSuccessM7Adjetivo = (TotalSuccessM7Adjetivo * 100)
        this.CharAciertosM7Adjetivo = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM7Adjetivo, (100 - TotalSuccessM7Adjetivo)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        TotalSuccessM7Oracion = (TotalSuccessM7Oracion / lengthStudents).toFixed(2);
        TotalSuccessM7Oracion = (TotalSuccessM7Oracion * 100)
        this.CharAciertosM7Oracion = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM7Oracion, (100 - TotalSuccessM7Oracion)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        TotalSuccessM7Sustantivo = (TotalSuccessM7Sustantivo / lengthStudents).toFixed(2);
        TotalSuccessM7Sustantivo = (TotalSuccessM7Sustantivo * 100)
        this.CharAciertosM7Sustantivo = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM7Sustantivo, (100 - TotalSuccessM7Sustantivo)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        TotalSuccessM7Verbo = (TotalSuccessM7Verbo / lengthStudents).toFixed(2);
        TotalSuccessM7Verbo = (TotalSuccessM7Verbo * 100)
        this.CharAciertosM7Verbo = {
          labels: ['success %', 'errors %'],
          datasets: [{
            label: 'Visits',
            data: [TotalSuccessM7Verbo, (100 - TotalSuccessM7Verbo)],
            backgroundColor: ['#008f88', '#ffa15d']
          }]
        }
        // quinto grafico
        if (FinalTestM7>0) {
          FinalTestM7 = (FinalTestM7 / lengthStudents).toFixed(2);
          //FinalTestM3 = (FinalTestM3 * 100)
          this.CharFinalTestM7 = {
            labels: ['% success', '% errors'],
            datasets: [{
              label: 'Visits',
              data: [FinalTestM7, (100 - FinalTestM7)],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          }
        } else {
          this.CharFinalTestM7 = { 
            labels: [],
            datasets: []
          };
        }
        // sexto grafico
        let TimeM7 = Math.round( TimeUseM7 );
        this.UseTimeM7 = this.secondsToString(TimeM7)
      }
    },
    SearchLettersDeficient (letters) {
      // console.log(letters)
      let idsLetters = Object.keys(letters)
      let arrayData = [];
      idsLetters.forEach(element => {
        if (letters[element].NPracticas>0) {
          arrayData.push({ id: element , NivelAprendizaje: letters[element].NivelAprendizaje, PorcentajeAcierto: (parseFloat(letters[element].PorcentajeAcierto).toFixed(2) * 100) })          
        }
      });
      arrayData.sort((a, b) => b.NivelAprendizaje - a.NivelAprendizaje)
      // console.log(arrayData)
      arrayData.length = 5;
      return arrayData;
    },
    SelectErrorArray (letters) {
      let temporalLetter = []
      letters.forEach(element => {
        element.forEach(element2 => {
          temporalLetter.push(element2);
        });
      });
      temporalLetter.sort((a,b) => {
        const bandA = a.id;
        const bandB = b.id;

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      // hasta aqui tengo el arreglo total ordenado, ahora les dare prioridad a los duplicados
      let arrayShorter=[];
      let partialletter= '';
      let amount = 0;
      let porcentOfSuccess = 0;
      // console.log(temporalLetter);
      temporalLetter.forEach((element, index, array) => {
        if (element.id == partialletter) {
          amount ++;
          porcentOfSuccess += element.PorcentajeAcierto;
        } else {
          if (partialletter == '') {
            partialletter = element.id;
            porcentOfSuccess += element.PorcentajeAcierto;
            amount ++;
          } else {
            porcentOfSuccess = (porcentOfSuccess / amount)
            arrayShorter.push({ id: partialletter, PorcentajeAcierto: porcentOfSuccess, amount: amount})
            partialletter = element.id;
            amount = 1;
            porcentOfSuccess = element.PorcentajeAcierto;
            // NOTA: falta comprobar el ultimo elemento
            if (array.length-1 == index) {
              porcentOfSuccess = (porcentOfSuccess / amount)
              arrayShorter.push({ id: partialletter, PorcentajeAcierto: porcentOfSuccess, amount: amount})
            }
          }
        }
      });
      arrayShorter.sort((a, b) => b.amount - a.amount)
      // console.log(arrayShorter);
      arrayShorter.length=5;
      return arrayShorter;
    },
    secondsToString (seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = (hour < 10)? '0' + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = (minute < 10)? '0' + minute : minute;
      var second = seconds % 60;
      second = (second < 10)? '0' + second : second;
      if (hour == '00') {
        return minute + ' minutes ' + second + ' seconds';
      }
      return hour + ' hours ' + minute + ' minutes ' + second + ' seconds';
    },
    SearchStudent () {
      
      if (this.studentsData.length == 0) {
        this.snackbar = true;
        return;
      }
      this.showChart = true;
      this.textTitle = this.studentSelected.name;
      console.log(this.studentSelected)
      let TotalSuccess = 0;
      let FinalTest = 0;
      let TimeUse = 0;
      let letersForPractice = [];
       // mision 2
      let TotalSuccessM2 = 0;
      let TimeUseM2 = 0;
      let letersForPracticeM2  = [];
      let FinalTestM2 = 0
      // mision 3
      let TotalSuccessM3 = 0;
      let TimeUseM3 = 0;
      let letersForPracticeM3  = [];
      let FinalTestM3 = 0;
      // mision 4
      let TotalSuccessM4 = 0;
      let TimeUseM4 = 0;
      let letersForPracticeM4Extensiones  = [];
      let letersForPracticeM4Inversas  = [];
      let letersForPracticeM4Inicio  = [];
      let FinalTestM4 = 0;
      // mision 5
      let TotalSuccessM5 = 0;
      let TimeUseM5 = 0;
      let letersForPracticeM5  = [];
      let FinalTestM5 = 0;
      // mision 6
      let TotalSuccessM6 = 0;
      let TimeUseM6 = 0;
      let letersForPracticeM6Trabadas  = [];
      let letersForPracticeM6Especiales  = [];
      let FinalTestM6 = 0;
      //mision 7
      let TotalSuccessM7Adjetivo = 0;
      let TotalSuccessM7Oracion = 0;
      let TotalSuccessM7Sustantivo = 0;
      let TotalSuccessM7Verbo = 0;
      let FinalTestM7 = 0;
      let TimeUseM7 = 0;
      let usersWithoutPlayM1 = 0;
      let usersWithoutPlayM2 = 0;
      let usersWithoutPlayM3 = 0;
      let usersWithoutPlayM4 = 0;
      let usersWithoutPlayM5 = 0;
      let usersWithoutPlayM6 = 0;
      let usersWithoutPlayM7 = 0;
//---------------cambiar : this.studentSelected.id --5d523ab3499a980acdbed6ca
      db.collection('Students').doc(this.studentSelected.id).get().then((res) => {
        let DataStudent = res.data();
        if (Object.prototype.hasOwnProperty.call(DataStudent, 'Alumno')) {
          this.Mission1Clean= false;
          TotalSuccess = parseFloat(res.data().Alumno.AprendizajeM1v2.PorcentajeAciertoT)
          FinalTest = parseFloat(res.data().Alumno.AprendizajeM1v2.PruebaFinal)
          TimeUse = parseFloat(res.data().Alumno.AprendizajeM1v2.TiempoTotalUso)
          letersForPractice = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM1v2.UnidadesAAprendizaje);
          if (TotalSuccess == 0) {
            this.Mission1Clean = true;
            this.InsufficientDataMission1 = true;
          }
          //mision 2
          if (res.data().Alumno.AprendizajeM2v2 == null) {
            this.Mission2Clean= true;
          }else{
            this.Mission2Clean= false;
            TotalSuccessM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.PorcentajeAciertoT);
            FinalTestM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.PruebaFinal);
            TimeUseM2 += parseFloat(res.data().Alumno.AprendizajeM2v2.TiempoTotalUso)
            console.log(res.data().Alumno.AprendizajeM2v2.UnidadesAAprendizaje)
            letersForPracticeM2 =this.SearchLettersDeficient(res.data().Alumno.AprendizajeM2v2.UnidadesAAprendizaje);
          }
          //mision 3
          if (res.data().Alumno.AprendizajeM3v2 == null) {
            this.Mission3Clean= true;
          }else{
            this.Mission3Clean= false;
            TotalSuccessM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.PorcentajeAciertoT);
            FinalTestM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.PruebaFinal);
            TimeUseM3 += parseFloat(res.data().Alumno.AprendizajeM3v2.TiempoTotalUso);
            letersForPracticeM3 = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM3v2.UnidadesAAprendizaje);
          }
          //mision 4
          if (res.data().Alumno.AprendizajeM4v2 == null) {
            this.Mission4Clean= true;
          }else{
            this.Mission4Clean= false;
            TotalSuccessM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.PorcentajeAciertoT);
            FinalTestM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.PruebaFinal);
            TimeUseM4 += parseFloat(res.data().Alumno.AprendizajeM4v2.TiempoTotalUso);
            letersForPracticeM4Extensiones = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM4v2.UnidadesAAprendizajeXEtapas.Etapa1);
            letersForPracticeM4Inversas = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM4v2.UnidadesAAprendizajeXEtapas.Etapa2);
            letersForPracticeM4Inicio = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM4v2.UnidadesAAprendizajeXEtapas.Etapa3);
          }
          //mision 5
          if (res.data().Alumno.AprendizajeM5v2 == null) {
            this.Mission5Clean= true;
          }else{
            this.Mission5Clean= false;
            TotalSuccessM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.PorcentajeAciertoT);
            FinalTestM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.PruebaFinal);
            TimeUseM5 += parseFloat(res.data().Alumno.AprendizajeM5v2.TiempoTotalUso);
            //letersForPracticeM4 = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM4v2.UnidadesAAprendizaje);
          }
          //mision 6
          if (res.data().Alumno.AprendizajeM6v2 == null) {
            this.Mission6Clean= true;
          }else{
            this.Mission6Clean= false;
            TotalSuccessM6 += parseFloat(res.data().Alumno.AprendizajeM6v2.PorcentajeAciertoT);
            FinalTestM6 += parseFloat(res.data().Alumno.AprendizajeM6v2.PruebaFinal);
            TimeUseM6 += parseFloat(res.data().Alumno.AprendizajeM6v2.TiempoTotalUso);
            letersForPracticeM6Trabadas = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM6v2.UnidadesAAprendizajeTrabadas);
            letersForPracticeM6Especiales = this.SearchLettersDeficient(res.data().Alumno.AprendizajeM6v2.UnidadesAAprendizajeEspeciales);
          }
          //mision 7
          //console.log(res.data().Alumno.AprendizajeM7v2);
          if (res.data().Alumno.AprendizajeM7v2 == null) {
            this.Mission7Clean= true;
          }else{
            this.Mission7Clean= false;
            TotalSuccessM7Adjetivo += parseFloat(res.data().Alumno.AprendizajeM7v2.UnidadesAAprendizajeTipos.Adjetivo.PorcentajeAcierto);
            TotalSuccessM7Oracion += parseFloat(res.data().Alumno.AprendizajeM7v2.UnidadesAAprendizajeTipos.Oracion.PorcentajeAcierto);
            TotalSuccessM7Sustantivo += parseFloat(res.data().Alumno.AprendizajeM7v2.UnidadesAAprendizajeTipos.Sustantivo.PorcentajeAcierto);
            TotalSuccessM7Verbo += parseFloat(res.data().Alumno.AprendizajeM7v2.UnidadesAAprendizajeTipos.Verbo.PorcentajeAcierto);
            FinalTestM7 += parseFloat(res.data().Alumno.AprendizajeM7v2.PruebaFinal);
            TimeUseM7 += parseFloat(res.data().Alumno.AprendizajeM7v2.TiempoTotalUso);
          }
          // console.log(letersForPractice)
          this.PaintCharts(TotalSuccess, letersForPractice, FinalTest, TimeUse, 1, TotalSuccessM2, FinalTestM2, TimeUseM2, letersForPracticeM2, TotalSuccessM3, FinalTestM3, TimeUseM3, letersForPracticeM3, TotalSuccessM4, FinalTestM4, TimeUseM4, letersForPracticeM4Extensiones, letersForPracticeM4Inversas, letersForPracticeM4Inicio, TotalSuccessM5, FinalTestM5, TimeUseM5, letersForPracticeM5, TotalSuccessM6, FinalTestM6, TimeUseM6, letersForPracticeM6Trabadas, letersForPracticeM6Especiales, TotalSuccessM7Adjetivo, TotalSuccessM7Oracion, TotalSuccessM7Sustantivo, TotalSuccessM7Verbo, FinalTestM7, TimeUseM7);
        } else {
          this.Mission1Clean = true;
          this.Mission2Clean = true;
          this.Mission3Clean = true;
          this.Mission4Clean = true;
          this.Mission5Clean = true;
          this.Mission6Clean = true;
          this.Mission7Clean = true;
          this.InsufficientDataMission1 = false;
        }
      })
      
    },
    // SelectGroup () {
    //   console.log(this.group)
    //   // if (this.group == ) {
    //   // }
    //   this.barChartData = {
    //     labels: ['a', 'e', 'i', 'o', 'u'],
    //     datasets: [
    //       {
    //         label: 'Visits',
    //         data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
    //         backgroundColor: '#003f5c'
    //       },
    //       {
    //         label: 'Pages Views',
    //         data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
    //         backgroundColor: '#2f4b7c'
    //       },
    //       {
    //         label: 'Users',
    //         data: [45, 65, 30, 53, 34],
    //         backgroundColor: '#665191'
    //       }
    //     ]
    //   };
    //   this.pieChartData = {
    //     labels: ['Aciertos', 'Errores'],
    //     datasets: [
    //       {
    //         label: 'Visits',
    //         data: [this.getRandomInt(), this.getRandomInt()],
    //         backgroundColor: ['#003f5c', '#2f4b7c']
    //       },
    //     ]
    //   }
    // },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  },
  computed: {
    myStyles () {
      return {
        height: `${this.height}px`,
      }
    }
  }
}
</script>
<style>
  .subtitle-statistics{
    font-size: 20px;
    display: block;
    text-align: center;
  }
  .time-use{
    font-size: 18px;
    font-weight: 700;
    color: black;
    background-color: #cecece;
    text-align: center;
    margin: 20px 20px 20px 20px;
    padding: 15px;
    border: 2px solid #848282;
    border-radius: 10px 10px;
    box-shadow: 4px 3px #d8d8d8;
  }
  #fondototal{
    background-image: url('../../../assets/admin/fondo-web-4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
